<template>
  <div>
    <b-modal id="showPer" hide-footer :title="`${nameOfRole} ( ${nameOfGroup} )`" centered>
      <ul class="px-3">
        <li class="list-modal" v-for="(permission, keyPermission) in selectedRole " :key="keyPermission"> {{
          permission.name
        }}</li>
      </ul>
    </b-modal>
    <div style=" border-radius: 8px; position: relative;">
      <b-row class="px-3 role-list" cols="5">
        <b-col cols="" class="role" v-for="role in Roles" :key="role">
          <h6 class="title-list">{{ role.name }}</h6>
          <div class="role-list-total">
            <!-- <p>Total users with this role: <span>4</span> </p> -->
            <p> Total users with this role : <span> {{ role.users_count }}</span></p>
            <ul class="lists mb-1 px-3" v-for="(value, key) in role.permissions" :key="value">
              <li class="list">{{ key }}
                <!-- <span class="text-success cursor-pointer" style="cursor: pointer; font-weight:bold"
                  @click="viewPermissionOfRole(value, role.name, key)">view Details</span> -->
              </li>
            </ul>
          </div>
          <div class="d-flex " align="end">
            <div class="" style="position:absolute ; bottom: 14px; right: 124px; margin-top: 0px; border-radius: 4px;">
              <b-button variant="green" class=" edit-role" v-b-modal.modal-more> show more</b-button>
            </div>
            <div class="" style="position:absolute ; bottom: 14px; right: 10px; margin-top: 0px; border-radius: 4px;">
              <b-button variant="green" class=" edit-role" v-b-modal.modal-2> Edit Role</b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="" class="role">
          <div class="add-role">
            <img src="../../../assets/images/Add role.png" alt="" class="add-role-img">
            <div
              style="position:absolute ; bottom: 14px; right: 80px; margin-top: 0px; border-radius: 4px; text-align: center;">
              <b-button variant="green-dark" class="btn-role mx-auto px-1 " v-b-modal.modal-2><i
                  class="fas fa-plus"></i>
                Add New Role</b-button>
            </div>
          </div>
          <div>
            <b-modal id="modal-more" ref="modal-more" title="Add New Role" size="lg" centered :hide-footer="true" class="modal-user ">
              <div>
                <div class="star-icon">
                  <h6>Role Name</h6>
                  <i class="fas fa-star px-1 "></i>
                </div>
                <div>
                  <b-form-input v-model="text" placeholder="Role name" class="mt-2 input-modal2"></b-form-input>
                  <div class="mt-3">
                    <div class="star-icon">
                      <h6 class="mb-2">Role Permissions</h6>
                      <i class="fas fa-star px-1 "></i>
                    </div>
                    <b-form-group>
                      <div class="d-flex mt-2 row">
                        <h6 class="col">Super Admin Access</h6>
                        <b-form-checkbox class="check-box2" v-for="option in option1" v-model="checked" disabled
                          :key="option.value" :value="option.value" name="flavour-3a">{{ option.name }}
                        </b-form-checkbox>
                      </div>
                    </b-form-group>

                  </div>

                  <b-form-group>
                    <div class="d-flex row">
                      <h6 class="col">User Management</h6>
                      <b-form-checkbox class="check-box" v-for="option in option2" v-model="checked" :key="option.value" disabled button-variant=""
                        :value="option.value" name="flavour-3a">{{ option.name }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex row">
                      <h6 class="col">content Management</h6>
                      <b-form-checkbox class="check-box" v-for="option in option3" v-model="checked" :key="option.value" disabled
                        :value="option.value" name="flavour-3a">{{ option.name }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                  <!-- <b-form-group>
            <div class="d-flex row ">
         <h6 class="col">Customer Management</h6>
       <b-form-checkbox
       class="check-box"
          v-for="option in option4"
          v-model="selected3"
          :key="option.value"
          :value="option.value"
          name="flavour-3a"
        >{{ option.name }}
        </b-form-checkbox>
      </div>
      </b-form-group> -->
                  <b-form-group>
                    <div class="d-flex row">
                      <h6 class="col">Project Manager</h6>
                      <b-form-checkbox class="check-box" v-for="option in option5" v-model="checked" :key="option.value" disabled
                        :value="option.value" name="flavour-3a">{{ option.name }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                  <b-form-group>
                    <div class="d-flex row">
                      <h6 class="col">Report Bulck</h6>
                      <b-form-checkbox class="check-box" v-for="option in option6" v-model="checked" :key="option.value" disabled
                        :value="option.value" name="flavour-3a">{{ option.name }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>

                  <div align="end" class="mt-4 mb-2">
                    <b-button class="btn-cancel" @click="hideModal">
                      Cancel</b-button>
                    <b-button variant="green-dark" class="btn-add"> Add Role</b-button>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
          <b-modal id="modal-2" ref="modal-edit" title="Add New Role" size="lg" centered :hide-footer="true" class="modal-user ">
            <div>
              <div class="star-icon">
                <h6>Role Name</h6>
                <i class="fas fa-star px-1 "></i>
              </div>
              <div>
                <b-form-input v-model="text" placeholder="Role name" class="mt-2 input-modal2"></b-form-input>
                <div class="mt-3">
                  <div class="star-icon">
                    <h6 class="mb-2">Role Permissions</h6>
                    <i class="fas fa-star px-1 "></i>
                  </div>
                  <b-form-group>
                    <div class="d-flex mt-2 row">
                      <h6 class="col">Super Admin Access</h6>
                      <b-form-checkbox class="check-box2" v-for="option in option1" v-model="selected"
                        :key="option.value" :value="option.value" name="flavour-3a">{{ option.name }}
                      </b-form-checkbox>
                    </div>
                  </b-form-group>

                </div>

                <b-form-group>
                  <div class="d-flex row">
                    <h6 class="col">User Management</h6>
                    <b-form-checkbox class="check-box" v-for="option in option2" v-model="selected1" :key="option.value"
                      :value="option.value" name="flavour-3a">{{ option.name }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
                <b-form-group>
                  <div class="d-flex row">
                    <h6 class="col">content Management</h6>
                    <b-form-checkbox class="check-box" v-for="option in option3" v-model="selected2" :key="option.value"
                      :value="option.value" name="flavour-3a">{{ option.name }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
                <!-- <b-form-group>
            <div class="d-flex row ">
         <h6 class="col">Customer Management</h6>
       <b-form-checkbox
       class="check-box"
          v-for="option in option4"
          v-model="selected3"
          :key="option.value"
          :value="option.value"
          name="flavour-3a"
        >{{ option.name }}
        </b-form-checkbox>
      </div>
      </b-form-group> -->
                <b-form-group>
                  <div class="d-flex row">
                    <h6 class="col">Project Manager</h6>
                    <b-form-checkbox class="check-box" v-for="option in option5" v-model="selected4" :key="option.value"
                      :value="option.value" name="flavour-3a">{{ option.name }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
                <b-form-group>
                  <div class="d-flex row">
                    <h6 class="col">Report Bulck</h6>
                    <b-form-checkbox class="check-box" v-for="option in option6" v-model="selected5" :key="option.value"
                      :value="option.value" name="flavour-3a">{{ option.name }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>

                <div align="end" class="mt-4 mb-2">
                  <b-button class="btn-cancel"  @click="hideModal2">
                    Cancel</b-button>
                  <b-button variant="green-dark" class="btn-add"> Add Role</b-button>
                </div>
              </div>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import RolesService from './services/roles'
export default {
  data () {
    return {
      checked: true,
      selectedRole: [],
      nameOfRole: '',
      nameOfGroup: '',
      permissions: [],
      Roles: [],
      selected: [],
      option1: [
        { item: 'selectAll', name: 'select All' }
      ],
      option2: [
        { item: 'Read', name: 'Read' },
        { item: 'Write', name: 'Write' },
        { item: 'Create', name: 'Create' }
      ],
      option3: [
        { item: 'Read', name: 'Read' },
        { item: 'Write', name: 'Write' },
        { item: 'Create', name: 'Create' }
      ],
      option4: [
        { item: 'Read', name: 'Read' },
        { item: 'Write', name: 'Write' },
        { item: 'Create', name: 'Create' }
      ],
      option5: [
        { item: 'Read', name: 'Read' },
        { item: 'Write', name: 'Write' },
        { item: 'Create', name: 'Create' }
      ],
      option6: [
        { item: 'Read', name: 'Read' },
        { item: 'Write', name: 'Write' },
        { item: 'Create', name: 'Create' }
      ],
      notification: {
        message: '',
        type: ''
      }
    }
  },
  methods: {
    viewPermissionOfRole (data, name, group) {
      this.nameOfRole = name
      this.nameOfGroup = group
      this.selectedRole = data
      this.$bvModal.show('showPer')
    },
    hideModal () {
      this.$refs['modal-more'].hide()
    },
    hideModal2 () {
      this.$refs['modal-edit'].hide()
    }
  },
  // mounted () {
  //   core.index()
  // },
  created () {
    RolesService.findAll().then(response => {
      this.Roles = response.data.data
      this.loading = false
    })
  }
  // data () {
  //   return {
  //   }

  // },
  // methods: {
  // }
}
</script>
<style lang="scss">
.list-modal{
  list-style-type: square;
}
.role-list{
 display: flex;
 gap: 15px;
}
.btn-cancel{
background-color:transparent !important;
color: #5D636F;
width: 67px;
height: 30px;
border: none;

}
.btn-cancel:hover{
  color: #5D636F;
}
.btn-cancel:focus{
  color: #5D636F;
}
.line{
  border-bottom: 6px solid red !important;
  width: 80%;
}
.edit-role{
height: 40px;
color: black !important;
}
.star-icon{
  display: flex;
  align-items: center;
  i{
    color: #f0416c;
    width: 90px;
    height: 22px;
    font-size: 8px;
  }
}
.check-box{
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}
.check-box2{
 padding-inline-end: 205px;
}
// .roles {
//   border-radius: 8px;
// }
.role {
  background-color: #FFFFFF;
  padding: 15px;
  border-radius: 8px;
  height: 380px;
  // margin-inline-end: 5px;
}
.lists{
  list-style-type: circle;
}
.title-list{
  font-weight:bold ;
  font-size: 16px;
}
.role-list-total{
  color:#8d8d8d;
  p{
    font-weight: bold;
  }
}
.btn-role{
  border: none;
  margin: 40px 10px 0 20px !important;
  padding: 12px 20px;
  border-radius: 4px;
  box-shadow: 2px 5px 12px 0 rgba(112, 112, 112, 0.3);

}
.add-role-img{
  width: 240px;
  height: 240px;
  display: block;
  margin-inline-start: 30px;
}
.add-role{
 text-align: center;
}
.input-modal2{
  padding: 20px 10px !important;
  margin: 10px 0px !important;
  border-top: 0.75px solid #D3D6DE !important;
  width: 100% !important;
  background-color: transparent;
}

</style>

<template>
  <div>
    <div>
      <header-style title="Users"/>
<div >
  <b-card class="bg-card">
    <b-tabs  active-nav-item-class="font-weight-bold text-uppercase  "
    content-class="mt-3">
      <b-tab title="Users List" active class="tab-nav">
      <b-card-text class="pt-3"> <user-list/> </b-card-text>
      </b-tab>
      <b-tab title="Roles List" >
        <b-card-text class="pt-3">
          <roles/>
        </b-card-text>
      </b-tab>
      <b-tab title="Permission Lists" >
        <b-card-text class="pt-3"> <permissions/>  </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</div>
    </div>

  </div>
</template>

<script>
import Permissions from './permissions/permissions.vue'
import Roles from '../userMangement/Roles/Roles.vue'
import userList from '../userMangement/userList/userList.vue'
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
export default {
  name: 'userMangement',
  components: {
    userList,
    Roles,
    Permissions,
    HeaderStyle
  }
}
</script>

<style>
.bg-tab{
  background-color: var(--iq-body-bg );
}

.text{
  color: var(--iq-primary-dark);
   text-align: center;
  width: 120px;
  height: 46px;
  flex-grow: 0;
  margin: 0 16px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
}

.bg-card{
background-color: transparent !important;
}

</style>

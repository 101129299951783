<template>
  <div>
    <div class="user-list-table-user">
      <div>
      <main-table
        :fields="fields"
        :items="items"
        :list_url="'users'"
        createBtnLabel="add User"
        createRouteName=""
        excelName="users"
        :downloadSampleStatus="true"
        downloadSampleUrl="admin/excel-sample"
        :uploadFileStatus="true"
        :createStatus="true"
        :reloadData="reloadData"
        uploadFileUrl="admin/excel"
        searchStatus="true"
        filterBtn="true"
      />
      <b-modal id="modal-1" title="Add user" size="lg" centered :hide-footer="true" class="modal-user ">
  <div >
    <form @submit.prevent="handleSubmit(onSubmit)">
    <div class="add-photo">
    <b-img v-bind="mainProps"  alt="Rounded image" class="mb-1 image-user mr-3" ></b-img>
    <button class="upload-photo">Upload Photo</button>
  </div>
    <div>
      <div class="my-3 ">
        <div class="label-name">
        <h6 class="font-title">Full name</h6>
        <i class="fas fa-star px-1"></i>
      </div>
        <input v-model="user.first_name" placeholder="Enter your full name" class="mt-2 input-modal">

      </div>
      <div class="">
      <div class="label-name ">
      <h6 class="font-title" for="emailInput">Email</h6>
      <i class="fas fa-star px-1"></i>
    </div>
      <div class="check-modal">
        <input id="emailInput" type="email" v-model="user.email" placeholder="example@domain.com"
          class="my-1 input-modal ">

      </div>
      <b-form-checkbox size="sm" class="mb-3"> Send invitation by email</b-form-checkbox>
    </div>
    <!-- <div class="form-group">
          <label for="passwordInput" class="label-login">{{ $t('global.password') }}</label> -->
          <!-- <router-link to="/auth/password-reset1" class="float-right">
            {{ $t('auth.forgotPassword') }}
          </router-link> -->
          <!-- <input type="password"
                 id="passwordInput"
                 v-model="user.password" :placeholder="$t('global.password')" required>
        </div> -->

      <!-- <div class="label-name">
      <h6 class=" font-title "> Role</h6>
      <i class="fas fa-star px-1 "></i>
    </div>
    <div class="check-modal mb-3">
        <b-form-input v-model="text" placeholder="Choose user role"
          class="my-1 input-modal "></b-form-input>

      </div>
      <div class="label-name">
      <h6 class=" font-title pb-2"> Projects</h6>
      <i class="fas fa-star px-1 "></i>
    </div>
    <b-col>
    <b-row class="project1" cols="4">
      <b-form-checkbox class="check-project">IBBIE</b-form-checkbox>
      <b-form-checkbox class="check-project">IBBIE</b-form-checkbox>
      <b-form-checkbox class="check-project">IBBIE</b-form-checkbox>
    </b-row>
    <b-row class="project2" cols="4">
       <b-form-checkbox class="check-project">IBBIE</b-form-checkbox>
      <b-form-checkbox class="check-project">IBBIE</b-form-checkbox>
    </b-row></b-col> -->
      <div  align="end" class="mt-3 mb-2" >
      <b-button class="btn-cancel" >
        Cancel</b-button>
      <b-button variant="green-dark" class="btn-add" type="submit" :disabled="loading" @click="onSubmit" >
        <b-spinner v-if="loading" small></b-spinner> Add user</b-button>
    </div>
    </div>
  </form>
  </div>
      <!-- <b-form class="form-top p-4" @submit.prevent="uploadFile">
        <b-button class="btn-download" variant="primary" @click="downloadSample" :disabled="downloadLoading">
          <b-spinner v-if="downloadLoading" small></b-spinner>
          <span class="mx-2">{{ $t('global.downloadSample') }}</span>
        </b-button>

        <input-file v-if="uploadFileStatus" v-model="file" :name="$t('global.file')" :label="$t('global.file')" :previewStatus="false" accept=".xls,.xlsx" />

        <form-btns v-if="uploadFileStatus" :label="$t('global.upload')" :loading="loading" />
      </b-form> -->
    </b-modal>
      </div>
  </div>
  </div>
</template>

<script>
import userServices from './services/user'
export default {
  props: ['first_name', 'last_name', 'phone', 'email', 'password'],
  name: 'userList',
  components: {},
  data () {
    return {
      loading: false,
      reloadData: false,
      user: {
        first_name: '',
        last_name: 'ahmed',
        // phone: '',
        email: '',
        password: '12345678',
        password_confirmation: '12345678'
      },
      mainProps: { blank: true, blankColor: '#777', width: 120, height: 120, class: 'm1' },
      fields: [
        { key: 'image', label: 'Image', type: 'image' },
        { key: 'first_name', label: 'user name', sortable: true },
        { key: 'email', label: 'Email' },
        { key: 'position', label: 'Position', sortable: true },
        { key: 'last_login', label: 'Last Login', sortable: true },
        { key: 'created_at', label: 'Joined Date', type: 'date', sortable: true },
        { key: 'status', label: 'Status', type: 'status', sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            {
              text: this.$t('global.edit'),
              icon: 'fas fa-pen',
              bgcolor: 'transparent',
              color: '',
              ifNavigate: false,
              routeName: 'clients.clientView',
              routeParams: ['id'],
              actionName: 'modal-1'
              // actionParams: 'fullObj'
            },
            {
              text: 'Deactivate',
              bgcolor: 'danger2',
              icon: '',
              color: '',
              ifNavigate: true,
              routeName: 'clients.clientView',
              routeParams: ['id']
            }

            // {
            //   text: this.$t(''),
            //   icon: 'fas fa-user-lock',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }
          ]
        }
      ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  },
  // mounted () {
  //   this.user.first_name = this.$props.first_name
  //   this.user.last_name = this.$props.last_name
  //   this.user.email = this.$props.email
  //   this.user.password = this.$props.password
  //   this.user.phone = this.$props.phone
  // },
  methods: {
    onSubmit () {
      this.loading = true
      userServices.Add(this.user).then(response => {
        this.$bvModal.hide('modal-1')
        this.reloadData = true
        setTimeout(() => {
          this.reloadData = false
        }, 500)
        this.$router.push({ name: 'userMangement' })
      }).finally(() => { this.loading = false })
    }
  }
}
</script>
<style scoped>
.btn-add{
  width: 20%;
  padding: 20px;
}
.btn-cancel{
background-color:transparent ;
color: #5D636F;
width: 67px;
height: 30px;
border: none;
}
.btn-cancel:hover{
  background-color: transparent;
}
.project2{
  gap: 20px;
  margin-top: 10px;
}
.project1{
gap: 20px;
}
.check-project{
  border: 2px solid #dee0e4;
  border-radius: 4px;
  padding: 15px 35px;

}
.image-user{
  border-radius: 50%;
}
.user-list-table-user{
  background-color: white;
  /* box-shadow: 0 4px 12px 2px rgba(102, 102, 102, 0.16); */
  padding: 0px;
 border-radius: 8px;
 margin-inline-end: 20px;
 margin-inline-start: 10px;
}
.pagination{
  /* border-radius:20px ; */
  margin: 10px;
  padding-bottom: 20px;
}
.upload-photo{
  border: 1px solid #E1E3E8;
  background: #F7F9FA;
  border-radius: 4px;
  padding: 8px 16px;
  width: 147px;
}
.input-modal{
  padding: 20px 10px !important;
  margin: 10px 0px !important;
  border: 0.75px solid #D3D6DE;
  height: 48px;
  width: 100%;
  border-radius: 4px !important;
}

</style>

<template>
  <div>
    <div class="user-list-table-permission">
      <div>
      <main-table
        :fields="fields"
        :list_url="'permissions'"
        :items="items"
        excelName="permission"
        :downloadSampleStatus="true"
        downloadSampleUrl="permission/excel-sample"
        uploadFileUrl="admin/excel"
        :addBtn="true"
        searchStatus="true"
        filterBtn="true"
      />
      <b-modal id="modal-3" title="Add Permission" size="lg" centered :hide-footer="true" class="modal-user ">
  <div>
    <div class="star-icon">
    <h6>Role Permission</h6>
    <i class="fas fa-star px-1 "></i>
  </div>
  <b-form-input v-model="text" placeholder="Permission name" class="mt-2 input-modal"></b-form-input>
  <div class="mt-3">
          <h6 class="mb-2">Assign To</h6>
          <b-form-group>
            <div class=" mt-2 ">
       <b-form-checkbox
       class="check-box2 pt-2"
          v-for="option in option1"
          v-model="selected"
          :key="option.value"
          :value="option.value"
          name="flavour-3a"
        >{{ option.name }}
        </b-form-checkbox>
      </div>
      </b-form-group>
      <div  align="center" class="mt-5" >
      <b-button class="discard-btn">
        Discard</b-button>
      <b-button variant="green-dark"> Add Permission</b-button>
    </div>
        </div>
  </div>
  </b-modal>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'userList',
  components: {},
  data () {
    return {
      selected: [],
      option1: [
        { item: 'selectAll', name: 'select All' },
        { item: 'superAdmin', name: 'Super Admin' },
        { item: 'projectManager', name: 'Project Manager' },
        { item: 'support', name: 'Support' }
      ],
      fields: [
        { key: 'name', label: 'name', sortable: true },
        { key: 'assigned_to_roles', label: 'assigned to', type: 'array', array_keys: ['name'] }
        // {
        //   key: 'actions',
        //   label: this.$t('table.actions'),
        //   type: 'actions',
        //   actions: [
        //     {
        //       text: this.$t('global.edit'),
        //       icon: 'fas fa-pen',
        //       bgcolor: 'transparent',
        //       color: '',
        //       ifNavigate: true,
        //       routeName: 'clients.clientView',
        //       routeParams: ['id']
        //     },
        //     {
        //       text: '',
        //       icon: 'fas fa-trash',
        //       bgcolor: 'dangerlight',
        //       color: '',
        //       ifNavigate: true,
        //       routeName: 'clients.clientView',
        //       routeParams: ['id']
        //     }
        //   ]
        // }
      ],
      // items: [
      //   { id: '20', Name: 'User Management', assignedTo: 'Super Admin  ,  project Manager', createdDate: '2022/10/21 20:30:15' },
      //   { id: '21', Name: 'Content Management', assignedTo: 'Super Admin  ,  project Manager', createdDate: '2022/10/22 20:30:15' },
      //   { id: '22', Name: 'Customer Management', assignedTo: 'Super Admin  ,  project Manager', createdDate: '2022/10/21 20:35:15' },
      //   { id: '23', Name: 'Projects Management', assignedTo: 'Super Admin  ,  project Manager', createdDate: '2022/10/21 20:40:15' },
      //   { id: '24', Name: 'Transaction', assignedTo: 'Super Admin  ,  project Manager', createdDate: '2022/10/21 20:30:12' }
      // ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  },
  methods: {
    openModal () {
      this.$bvModal.show('modal-3')
    },
    closeModal () {
      this.$bvModal.hide('modal-3')
    }
  }
}
</script>
<style>
.discard-btn{
  background-color: transparent !important;
  color: black;
  border: none;
}
.user-list-table-permission{
  background-color: white;
  /* box-shadow: 0 4px 12px 2px rgba(102, 102, 102, 0.16); */
  padding: 0px;
  border-radius: 8px;
 margin-inline-end: 20px;
 margin-inline-start: 10px;
}
.pagination{
  border-radius:20px ;
  margin: 10px;
}

</style>

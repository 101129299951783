import addUser from '@/axios'

export default {
  Add (userData) {
    return addUser().post('users', userData)
  },
  findOne (id) {
    return addUser().post(`users/${id}`)
  }
}
